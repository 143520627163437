import React from 'react'
import {
  InstantSearch,
  Index,
  Configure,
  connectHighlight,
  connectStateResults,
  connectSearchBox,
  connectHits,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { Icon } from 'components/icon/Icon.js'
import Img from 'components/img/cloudinary-img'
import DropdownMenu from 'components/dropdown'
import AeditLink from 'services/link'
import { Track } from 'services/analytics'
import { getInitials } from 'services/util/format'

const algoliaClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY, {
  _useRequestCache: true,
})

// patch searchClient so that it doesn't fire an empty search query on mount
// we're not doing anything with these results so they are wasted operations
// https://github.com/algolia/react-instantsearch/issues/1111#issuecomment-496132977
let firstLoad = true

const searchClient = {
  search(requests) {
    if (firstLoad === true) {
      firstLoad = false
      return
    }

    return algoliaClient.search(requests)
  },
}

const ProviderIndex = ({ children, ...props }) => (
  <Index indexId="provider-index" indexName={process.env.ALGOLIA_PROVIDERS_INDEX} {...props}>
    <Configure filters="booking_available = 1" />
    {children}
  </Index>
)

const ArticleIndex = props => (
  <Index indexId="article-index" indexName={process.env.ALGOLIA_ARTICLES_INDEX} {...props} />
)

const ProcedureIndex = props => (
  <Index indexId="procedure-index" indexName={process.env.ALGOLIA_PROCEDURES_INDEX} {...props} />
)

const ConcernIndex = props => (
  <Index indexId="concern-index" indexName={process.env.ALGOLIA_CONCERNS_INDEX} {...props} />
)

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  })

  return (
    <p>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <span key={index} className="bg-legacy-tan">
            {part.value}
          </span>
        ) : (
          <React.Fragment key={index}>{part.value}</React.Fragment>
        )
      )}
    </p>
  )
}

const CustomHighlight = connectHighlight(Highlight)

const ConcernHit = ({ hit, query }) => (
  <AeditLink
    pathname="/concern/[slug]"
    as={`/concern/${hit.slug}`}
    onClick={() => Track.searchQueryClicked(query)}>
    <CustomHighlight attribute="name" hit={hit} />
  </AeditLink>
)

const ProcedureHit = ({ hit, query }) => (
  <AeditLink
    className="transition-all hover:opacity-50"
    pathname="/procedure/[slug]"
    as={`/procedure/${hit.slug}`}
    onClick={() => Track.searchQueryClicked(query)}>
    <CustomHighlight attribute="name" hit={hit} />
  </AeditLink>
)

const ArticleHit = ({ hit, query }) => (
  <AeditLink
    className="flex mb-2 transition-all hover:opacity-50"
    pathname="/aedition/[slug]"
    as={`/aedition/${hit.slug}`}
    onClick={() => Track.searchQueryClicked(query)}>
    <div className="mr-4 min-w-[6rem] w-24 h-20">
      <Img
        className="h-full object-cover"
        publicId={hit?.articleImage?.url}
        gravity="face"
        crop="thumb"
        zoom="0.8"
      />
    </div>

    <CustomHighlight attribute="title" hit={hit} />
  </AeditLink>
)

const ProviderHit = ({ hit, query }) => {
  const location = hit?.primary_practice?.location
  const pic = hit?.profile_picture_relative_url
  const providerType = hit?.types?.find(type => type.display_name)

  return (
    <AeditLink
      className="mb-6 transition-all hover:opacity-50"
      as={`/provider/${hit.slug}`}
      pathname="/provider/[slug]">
      <div className="flex" onClick={() => Track.searchQueryClicked(query)}>
        <div className="relative flex items-center mr-6">
          {Boolean(hit.accept_video_consultations) && (
            <div className="flex items-center justify-center w-6 h-6 absolute right-0 bottom-0 rounded-full bg-green z-10">
              <Icon name="video" stroke="white" width={16} height={16} />
            </div>
          )}
          {pic ? (
            <div className="w-20 h-20 rounded-full overflow-hidden">
              <Img publicId={pic} gravity="face" crop="thumb" zoom="0.8" />
            </div>
          ) : (
            <div className="flex justify-center items-center w-20 h-20 bg-light-gray rounded-full">
              {getInitials(`${hit.first_name} ${hit.last_name}`)}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <span className="flex">
            <CustomHighlight attribute="first_name" hit={hit} />
            <span>&nbsp;</span>
            <CustomHighlight attribute="last_name" hit={hit} />
          </span>
          {providerType && <span className="">{providerType.display_name}</span>}
          {location && (
            <span className="body-text-md">
              {location.city}, {location?.state_abbreviation}
            </span>
          )}
        </div>
      </div>
    </AeditLink>
  )
}

const CustomHits = connectHits(
  ({ hits, label, query, route, hitComponent: HitComponent }) =>
    Boolean(hits?.length) && (
      <>
        <DropdownMenu.Box className="px-6 md:mx-6 md:w-auto">
          {label}

          <ul>
            {hits.slice(0, 4).map(hit => (
              <li key={hit.objectID} className="mb-4 transition-all hover:opacity-50">
                <HitComponent hit={hit} query={query} />
              </li>
            ))}
          </ul>

          {route && (
            <AeditLink
              pathname={route}
              className="t-overline underline block dark-gray mt-2 md:mb-6">
              View all
            </AeditLink>
          )}
        </DropdownMenu.Box>

        <div className="h-px w-full bg-light-gray my-8 md:hidden" />
      </>
    )
)

const Results = connectStateResults(({ searchResults, allSearchResults }) => {
  const queryActive = Boolean(searchResults?.query?.length)
  const totalResults = () => {
    if (!allSearchResults) {
      return 0
    }

    if (allSearchResults.nbHits > -1) {
      return allSearchResults.nbHits
    } else {
      return Object.values(allSearchResults)
        .map(o => o.nbHits)
        .reduce((acc, current) => acc + current)
    }
  }

  // To achieve multi-index searching in algolia, we need to render each of the indexes all the time.
  if (!queryActive || totalResults() < 1) {
    return (
      <>
        <ConcernIndex />
        <ProcedureIndex />
        <ProviderIndex />
        <ArticleIndex />
      </>
    )
  }

  return (
    <div className="bg-white md:flex-1 lg:px-12">
      <div className="max-w-screen-xl mx-auto overflow-scroll md:flex ">
        <div className="w-full max-w-fit md:w-1/2 lg:w-auto">
          <ConcernIndex>
            <CustomHits
              hitComponent={ConcernHit}
              label={
                <div className="flex items-center mb-6">
                  <Icon name="heart" className="mr-4" />
                  <p>Concerns</p>
                </div>
              }
              query={searchResults.query}
              route="/concerns"
            />
          </ConcernIndex>

          <ProcedureIndex>
            <CustomHits
              hitComponent={ProcedureHit}
              label={
                <div className="flex items-center mb-6">
                  <Icon name="clipboard" className="mr-4" />
                  <p>Procedures</p>
                </div>
              }
              query={searchResults.query}
              route="/procedures"
            />
          </ProcedureIndex>
        </div>

        <div className="flex flex-col w-full max-w-fit md:w-1/2 lg:w-auto lg:flex-row">
          <ProviderIndex>
            <CustomHits
              hitComponent={ProviderHit}
              label={
                <div className="flex items-center mb-6">
                  <Icon name="med-cross" className="mr-4" />
                  <p>Providers</p>
                </div>
              }
              query={searchResults.query}
              route="/providers"
            />
          </ProviderIndex>

          <ArticleIndex>
            <CustomHits
              hitComponent={ArticleHit}
              label={
                <div className="flex items-center mb-6">
                  <Icon name="book" className="mr-4" />
                  <p>AEDITION</p>
                </div>
              }
              query={searchResults.query}
              route="/aedition"
            />
          </ArticleIndex>
        </div>
      </div>
    </div>
  )
})

const SearchBox = ({ currentRefinement, refine }) => {
  const placeholder =
    typeof window !== 'undefined' && window?.outerWidth < 768
      ? 'Type to search'
      : 'Search for Providers, Solutions, or AEDITION Articles'

  return (
    <form noValidate action="" role="search" className="max-w-screen-xl mx-auto  px-6 lg:px-12">
      <input
        autoFocus
        className="p-4 w-full bg-light-gray border-none rounded-md"
        type="search"
        value={currentRefinement}
        placeholder={placeholder}
        onChange={event => refine(event.currentTarget.value)}
      />
    </form>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

const NavSearch = ({ isShown, setClosed }) => {
  if (!isShown) return null

  return (
    <InstantSearch searchClient={searchClient} indexName={process.env.ALGOLIA_PROCEDURES_INDEX}>
      <DropdownMenu
        className="flex flex-col fixed top-mobile-nav md:top-nav left-0 right-0 bottom-0 bg-white overflow-scroll md:bg-transparent"
        isShown={isShown}
        setClosed={setClosed}
        clickToExit>
        <DropdownMenu.Box className="pt-6 bg-white md:py-6">
          <CustomSearchBox />

          <div className="h-px w-full bg-light-gray my-8 md:hidden" />
        </DropdownMenu.Box>
        <Results />
      </DropdownMenu>
    </InstantSearch>
  )
}

export default NavSearch
